<template>
    
    <div class="page overflow-hidden bg-background w-screen">
      
            <div class="flex items-center justify-center h-screen overflow-hidden w-full">
                <slot />
            </div>

  </div>
</template>
